import { type Configuration, EventType, PublicClientApplication } from '@azure/msal-browser'

const configuration: Configuration = {
  auth: {
    clientId: '0d256db4-f276-49b0-99b6-f4c8de371e88',
    authority: 'https://login.microsoftonline.com/c82e6ef5-2765-4097-8d0d-07ce70b71869',
  },
}

const msalInstance = new PublicClientApplication(configuration)

export async function getApiAccessToken() {
  if (!msalInstance?.getActiveAccount()) return null

  const token = await msalInstance.acquireTokenSilent({
    account: msalInstance.getActiveAccount()!,
    scopes: ['api://0d256db4-f276-49b0-99b6-f4c8de371e88/access_as_user'],
  })

  return token?.accessToken
}

msalInstance.addEventCallback((message) => {
  if (message.eventType === EventType.LOGIN_SUCCESS && message.payload) {
    const { account } = message.payload as any
    void msalInstance
      .acquireTokenSilent({
        account,
        scopes: ['api://0d256db4-f276-49b0-99b6-f4c8de371e88/access_as_user'],
      })
      .then((token) => {
        void fetch(`${process.env.REACT_APP_API_URL}/signup`, {
          method: 'POST',
          headers: {
            Authorization: `Bearer ${token.accessToken}`,
          },
        })
      })
  }
})

export default msalInstance
