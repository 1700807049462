import { Dialog } from '@headlessui/react'
import { Person, PersonCardInteraction, PersonViewType } from '@microsoft/mgt-react'
import { startOfDay, subDays, eachDayOfInterval, format } from 'date-fns'
import { useState, useMemo, useEffect } from 'react'
import fetcher from '../../lib/fetcher'
import useSWR from 'swr'

interface Props {
  teamId: string
  isOpen: boolean
  onClose: () => void
}

export default function Team30DaysCheckIns({ teamId, isOpen, onClose }: Props) {
  const [from] = useState<Date>(startOfDay(subDays(new Date(), 29)))
  const [days, setDays] = useState<Date[]>([])

  const params = useMemo(
    () => ({
      from: from.getTime(),
    }),
    [from]
  )

  useEffect(() => {
    const range = eachDayOfInterval({
      start: from,
      end: new Date(),
    })

    setDays(range)
  }, [from])

  const { data: checkIns, isLoading } = useSWR(
    [`/analytics/team/${teamId}/check-ins`, params],
    async ([url, params]) => await fetcher(url, params)
  )

  const [reducedCheckIns, setReducedCheckIns] = useState()

  useEffect(() => {
    if (checkIns?.data) {
      const transformedResults = checkIns.data.reduce(
        (r: any, checkIn: { personId: string; hours: number; postedAt: Date }) => {
          const { personId, hours, postedAt } = checkIn
          const date = format(new Date(postedAt), 'dd/MM')

          if (!r[personId]) {
            r[personId] = {
              [date]: hours,
            }
          } else {
            if (r[personId][date]) {
              r[personId][date] = (Number(r[personId][date]) || 0) + hours
            } else {
              r[personId][date] = hours
            }
          }

          return r
        },
        {}
      )

      setReducedCheckIns(transformedResults)
    }
  }, [checkIns])

  return (
    <Dialog open={isOpen} onClose={onClose} className="relative z-50">
      {/* The backdrop, rendered as a fixed sibling to the panel container */}
      <div className="fixed inset-0 bg-black/30" aria-hidden="true" />

      {/* Full-screen scrollable container */}
      <div className="fixed inset-0">
        {/* Container to center the panel */}
        <div className="flex min-h-full items-center justify-center p-4">
          {/* The actual dialog panel  */}
          <Dialog.Panel className="mx-auto w-full md:w-2/3 rounded-xl bg-white p-4 relative">
            <svg
              onClick={onClose}
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-5 h-5 absolute right-3 top-3 cursor-pointer">
              <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
            </svg>

            <Dialog.Title className="text-lg font-bold tracking-wide mb-4">
              Last 30 days
            </Dialog.Title>
            <div className="overflow-auto max-h-[70vh]">
              <table className="min-w-full divide-y divide-gray-200 text-xs">
                <thead className="sticky top-0 z-10 bg-white shadow-sm p-2 text-xs">
                  <tr>
                    <th className="p-1 sticky left-0 bg-white"></th>
                    {days.map((d, i) => (
                      <th key={`col-${i}`} className="p-2 font-semibold text-gray-600">
                        {format(d, 'dd/MM')}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200">
                  {!isLoading &&
                    reducedCheckIns &&
                    Object.entries(reducedCheckIns).map(([personId, checkIns]: [string, any]) => (
                      <tr key={personId}>
                        <td className="p-2 sticky left-0 bg-white">
                          <Person
                            userId={personId}
                            personCardInteraction={PersonCardInteraction.hover}
                            view={PersonViewType.oneline}
                          />
                        </td>
                        <>
                          {days.map((d, i) => (
                            <td key={`col-d-${i}`} className="text-center p-2 font-light">
                              {!checkIns[format(d, 'dd/MM')] ? (
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  viewBox="0 0 20 20"
                                  fill="currentColor"
                                  className="w-4 h-4 mx-auto text-red-500">
                                  <path
                                    fillRule="evenodd"
                                    d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.28 7.22a.75.75 0 00-1.06 1.06L8.94 10l-1.72 1.72a.75.75 0 101.06 1.06L10 11.06l1.72 1.72a.75.75 0 101.06-1.06L11.06 10l1.72-1.72a.75.75 0 00-1.06-1.06L10 8.94 8.28 7.22z"
                                    clipRule="evenodd"
                                  />
                                </svg>
                              ) : (
                                <span>{checkIns[format(d, 'dd/MM')]}h</span>
                              )}
                            </td>
                          ))}
                        </>
                      </tr>
                    ))}
                  {isLoading && (
                    <>
                      <tr className="animate-pulse">
                        <td className="p-1 text-center">
                          <div className="w-6 h-6 bg-slate-300 rounded-full"></div>
                        </td>
                        {[1, 2, 3, 4, 5, 6].map((_, i) => (
                          <td key={i} className="p-1 text-center">
                            <div className="w-3 h-3 bg-slate-300 rounded-full"></div>
                          </td>
                        ))}
                      </tr>
                      <tr className="animate-pulse">
                        <td className="p-1 text-center">
                          <div className="w-6 h-6 bg-slate-300 rounded-full"></div>
                        </td>
                        {[1, 2, 3, 4, 5, 6].map((_, i) => (
                          <td key={i} className="p-1 text-center">
                            <div className="w-3 h-3 bg-slate-300 rounded-full"></div>
                          </td>
                        ))}
                      </tr>
                    </>
                  )}
                </tbody>
              </table>
            </div>
          </Dialog.Panel>
        </div>
      </div>
    </Dialog>
  )
}
