import { MsalAuthenticationTemplate } from '@azure/msal-react'
import { InteractionType } from '@azure/msal-browser'
import './App.css'

import { createBrowserRouter, RouterProvider } from 'react-router-dom'
import Root from './routes/root'
import Home from './routes/home'
import AddTeam from './routes/teams/add'
import Team from './routes/teams/team'
import Person from './routes/person'
import Profile from './routes/profile/profile'

function ErrorComponent(error: any) {
  return <p>An Error Occurred: {JSON.stringify(error)}</p>
}

function LoadingComponent() {
  return (
    <div className="w-full h-full flex justify-center items-center">
      <p>Wait while we are authenticating you</p>
    </div>
  )
}

const router = createBrowserRouter([
  {
    path: '/',
    element: <Root />,
    children: [
      {
        index: true,
        element: <Home />,
      },
      {
        path: '/me',
        element: <Profile />,
      },
      {
        path: '/teams/add',
        element: <AddTeam />,
      },
      {
        path: '/teams/:id',
        element: <Team />,
      },
      {
        path: ':id',
        element: <Person />,
      },
    ],
  },
])

function App() {
  const authRequest = {
    scopes: ['openid', 'profile'],
  }
  return (
    <MsalAuthenticationTemplate
      interactionType={InteractionType.Redirect}
      authenticationRequest={authRequest}
      errorComponent={ErrorComponent}
      loadingComponent={LoadingComponent}>
      <RouterProvider router={router} />
    </MsalAuthenticationTemplate>
  )
}

export default App
