import { Dialog } from '@headlessui/react'
import { Person } from '@microsoft/mgt-react'
import { useEffect, useRef, useState } from 'react'
import ReactQuill from 'react-quill'
import { getApiAccessToken } from '../../msal'
import LoadingDots from '../LoadingDots'

interface Props {
  firstInput: string
  isOpen: boolean
  hours: number
  onReplied: () => void
  onDismiss: () => void
}

export default function FollowUp({ firstInput, hours, isOpen, onReplied, onDismiss }: Props) {
  const [value, setValue] = useState('')
  const [isSubmitting, setSubmitting] = useState(false)
  const quillRef = useRef<ReactQuill>(null)
  const [followUp, setFollowUp] = useState<string>('')
  const [fetchingFollowUp, setFetchingFollowUp] = useState(true)
  const [postId, setPostId] = useState<number>()

  useEffect(() => {
    const fetchFollowUp = async () => {
      setFetchingFollowUp(true)
      try {
        const accessToken = await getApiAccessToken()
        if (!accessToken)
          throw new Error('Unable to authorize the request, please log off and log in')

        const apiResult = await fetch(`${process.env.REACT_APP_API_URL}/post`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
          },
          body: JSON.stringify({
            content: firstInput,
            hours,
          }),
        })

        if (!apiResult.ok) {
          throw new Error('An error occurred while posting your update.')
        }

        const data = await apiResult.json()

        if (data.followUp?.length > 0) {
          setFollowUp(data.followUp[0].text)
          setPostId(data.postId)
        } else {
          // await mutate({ data: [...feed?.data, data] })
        }
      } catch (err) {
        console.log(err)
        throw err
      } finally {
        setFetchingFollowUp(false)
      }
    }
    if (!firstInput || postId) return
    setFollowUp('')
    void fetchFollowUp()
  }, [firstInput])

  const onReply = async () => {
    setSubmitting(true)
    if (quillRef?.current?.unprivilegedEditor) {
      const text = quillRef.current.unprivilegedEditor.getText()
      if (text.trim().length === 0) {
        setSubmitting(false)
        return
      }
    }

    try {
      const accessToken = await getApiAccessToken()
      if (!accessToken)
        throw new Error('Unable to authorize the request, please log off and log in')

      if (!postId) throw new Error('Something went wrong, threadId is null')

      const apiResult = await fetch(`${process.env.REACT_APP_API_URL}/post/${postId}/reply`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({
          content: value,
        }),
      })

      if (!apiResult.ok) {
        throw new Error('An error occurred while posting your update.')
      }
    } catch (err) {
      console.log(err)
    } finally {
      setSubmitting(false)
      onReplied()
    }
  }

  return (
    <Dialog open={isOpen} onClose={onDismiss} className="relative z-50">
      <div className="fixed inset-0 bg-black/30" aria-hidden="true" />
      <div className="fixed inset-0 overflow-scroll">
        <div className="flex min-h-full items-center justify-center">
          <Dialog.Panel className="mx-auto w-full max-w-2xl rounded-xl bg-white relative">
            <Dialog.Description>
              <div className="flex flex-col gap-4 px-4 pt-4">
                <div className="max-w-lg ml-auto bg-purple-100 px-4 py-1 rounded-xl">
                  <div dangerouslySetInnerHTML={{ __html: firstInput }}></div>
                </div>
                {followUp && (
                  <div className="max-w-lg mr-auto bg-gray-100 px-4 py-1 rounded-xl">
                    {followUp}
                  </div>
                )}
                {fetchingFollowUp && (
                  <div className="mr-auto">
                    <LoadingDots />
                  </div>
                )}
              </div>
            </Dialog.Description>
            <div className="w-full px-4 pt-2 mt-2 bg-white border border-b-0">
              <div className="flex flex-row">
                <Person personQuery="me" className="py-2" avatarSize="large" />
                <div className="flex-1">
                  <ReactQuill
                    ref={quillRef}
                    theme="bubble"
                    placeholder="Write your reply"
                    value={value}
                    onChange={(value) => {
                      setValue(value)
                    }}
                    formats={['bold', 'italic', 'underline', 'list', 'bullet']}
                  />
                </div>
              </div>
              <div className="border-t flex flex-row justify-end items-center mt-4 py-2">
                <button
                  className="btn"
                  disabled={!value || value.length === 0}
                  onClick={() => {
                    if (!isSubmitting) {
                      void onReply()
                    }
                  }}>
                  {isSubmitting ? <LoadingDots /> : 'Share'}
                </button>
              </div>
            </div>
          </Dialog.Panel>
        </div>
      </div>
    </Dialog>
  )
}
