import { PeoplePicker } from '@microsoft/mgt-react'
import classNames from 'classnames'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { mutate } from 'swr'
import { getApiAccessToken } from '../../msal'

export default function AddTeam() {
  const [name, setName] = useState('')
  const [people, setPeople] = useState([])
  const navigate = useNavigate()
  const [isSubmitting, setSubmitting] = useState(false)

  const onSave = () => {
    if (!name || !people || people.length === 0) {
      return
    }
    setSubmitting(true)
    getApiAccessToken()
      .then((accessToken) => {
        if (!accessToken) return

        fetch(`${process.env.REACT_APP_API_URL}/team`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${accessToken}`,
          },
          body: JSON.stringify({
            name,
            members: people.map((person: any) => person.id),
          }),
        })
          .then(() => {
            void mutate('/my-teams')
            navigate(-1)
          })
          .finally(() => {
            setSubmitting(false)
          })
      })
      .catch((err) => {
        console.log(err)
      })
  }

  return (
    <div className="grid grid-cols-1 md:grid-cols-3">
      <div className="col-span-2">
        <div className="bg-white p-4 border">
          <h2 className="text-2xl font-bold tracking-tight text-gray-900">Add a Team</h2>
          <p className="text-gray-600 text-sm mb-3">
            Teams are just a list of accounts that you may want to follow.
          </p>
          <input
            type="text"
            placeholder="Team Name"
            className="px-4 border border-gray-300 rounded-3xl w-full max-w-xs !outline-none outline-offset-0 h-[38px]"
            value={name}
            onChange={(e) => {
              setName(e.target.value)
            }}
          />
          <div className="mt-4">
            <div className="border border-gray-300 rounded-3xl px-2">
              <PeoplePicker
                placeholder="Choose your colleagues"
                selectionChanged={(e: any) => {
                  setPeople(e.target.selectedPeople)
                }}
              />
            </div>
          </div>
          <div className="mt-4 flex flex-row-reverse">
            <button
              className={classNames('btn', {
                loading: isSubmitting,
              })}
              disabled={isSubmitting}
              onClick={onSave}>
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
