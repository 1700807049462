import { Person, PersonViewType } from '@microsoft/mgt-react'
import classNames from 'classnames'
import { formatRelative } from 'date-fns'
import { Link } from 'react-router-dom'
import './Feed.css'

export interface FeedItemProps {
  id: number
  authorId: string
  content: string
  hours: number
  timestamp: Date
  replies: Array<{
    id: number
    authorId: string
    content: string
    createdAt: Date
  }>
}

function FeedItem({ data }: { data: FeedItemProps }) {
  const { authorId, content: htmlContent, hours, timestamp, replies } = data
  return (
    <div className="p-4 bg-white">
      <div className="flex flex-row items-center justify-between">
        <Link to={`/${authorId}`}>
          <Person userId={authorId} view={PersonViewType.oneline} avatarSize="small" />
        </Link>
        <div className="text-xs text-gray-500">
          <p>
            {hours && `${hours}h • `}
            {timestamp && formatRelative(new Date(timestamp), new Date())}
          </p>
        </div>
      </div>
      <div
        className={classNames('feed-item pb-2', {
          'border-l': replies.length > 0,
        })}
        dangerouslySetInnerHTML={{ __html: htmlContent }}></div>
      {replies.map(({ authorId, content, createdAt }, i) => (
        <>
          <div className="flex flex-row items-center gap-2">
            <Link to={`/${authorId}`}>
              <Person userId={authorId} view={PersonViewType.oneline} avatarSize="small" />
            </Link>
            <p className="text-xs text-gray-500">
              {timestamp && formatRelative(new Date(createdAt), new Date())}
            </p>
          </div>
          <div
            className={classNames('feed-item', {
              'border-l pb-2': i !== replies.length - 1,
            })}
            dangerouslySetInnerHTML={{ __html: content }}></div>
        </>
      ))}
    </div>
  )
}

interface FeedProps {
  items?: FeedItemProps[]
  loading: boolean
  error?: any
}

export default function Feed({ items, error, loading }: FeedProps) {
  if (error) {
    return <div>{JSON.stringify(error)}</div>
  }

  if (loading) {
    return (
      <>
        {/* <p className="p-8 text-center text-gray-500">Fetching updates ..</p> */}
        <div className="px-4 py-4">
          <div className="flex flex-row gap-2 py-2">
            <div className="h-7 w-7 shrink-0  animate-pulse bg-slate-200 rounded-full"></div>
            <div className="w-full h-12 animate-pulse bg-slate-200 rounded-sm"></div>
          </div>
          <div className="flex flex-row gap-2 py-2">
            <div className="h-7 w-7 shrink-0  animate-pulse bg-slate-200 rounded-full"></div>
            <div className="w-full h-12 animate-pulse bg-slate-200 rounded-sm"></div>
          </div>
          <div className="flex flex-row gap-2 py-2">
            <div className="h-7 w-7 shrink-0  animate-pulse bg-slate-200 rounded-full"></div>
            <div className="w-full h-12 animate-pulse bg-slate-200 rounded-sm"></div>
          </div>
        </div>
      </>
    )
  }

  if (!loading && (!items || items.length === 0)) {
    return <p className="p-8 text-center text-gray-500">No updates from anyone 🤐</p>
  }

  return (
    <ul className="flex flex-col divide-y">
      {items?.map((item) => (
        <li key={item.id}>
          <FeedItem data={item} />
        </li>
      ))}
    </ul>
  )
}
