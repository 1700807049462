import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { MsalProvider } from '@azure/msal-react'
import { CacheService, Providers } from '@microsoft/mgt-element'
import { Msal2Provider } from '@microsoft/mgt-msal2-provider'
import msalInstance from './msal'

Providers.globalProvider = new Msal2Provider({
  publicClientApplication: msalInstance,
  scopes: ['user.read'],
})

CacheService.config.photos.invalidationPeriod = 5 * 24 * 60 * 60 * 1000

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement)
root.render(
  <MsalProvider instance={msalInstance}>
    <App />
  </MsalProvider>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
