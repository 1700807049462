import { People, PeoplePicker, Person, PersonCardInteraction } from '@microsoft/mgt-react'
import classNames from 'classnames'
import { useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import Feed from '../../components/Feed'
import useSWR from 'swr'
import fetcher from '../../lib/fetcher'
import { getApiAccessToken } from '../../msal'
import { eachDayOfInterval, format, startOfDay, subDays } from 'date-fns'
import Team30DaysCheckIns from '../../components/Analytics/Team30DaysCheckIns'

interface TeamProfileProps {
  id: string
}

function TeamProfile({ id }: TeamProfileProps) {
  const { data, isLoading, mutate } = useSWR(`/team/${id}`, fetcher)

  const [isEditing, setEditing] = useState(false)
  const [teamMembersIds, setTeamMembersIds] = useState<string[]>([])
  const [isSubmitting, setSubmitting] = useState(false)

  useEffect(() => {
    if (data?.members) {
      setTeamMembersIds(data.members.map((member: any) => member.personId))
    }
  }, [data])

  const updateMembers = async () => {
    try {
      setSubmitting(true)
      const accessToken = await getApiAccessToken()
      if (!accessToken)
        throw new Error('Unable to authorize the request, please log off and log in')

      const apiResult = await fetch(process.env.REACT_APP_API_URL + `/team/${id}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
        body: JSON.stringify({
          members: teamMembersIds,
        }),
      })

      if (!apiResult.ok) {
        throw new Error(await apiResult.json())
      }
      setEditing(false)
      await mutate()
    } catch (err) {
      console.log(err)
    } finally {
      setSubmitting(false)
    }
  }

  if (isLoading || !data) {
    return (
      <div className="bg-white p-4 pt-6 animate-pulse border-b">
        <div className="flex flex-row items-center pb-1">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="w-5 h-5">
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M5.25 8.25h15m-16.5 7.5h15m-1.8-13.5l-3.9 19.5m-2.1-19.5l-3.9 19.5"
            />
          </svg>
          <div className="h-6 w-full bg-slate-200 rounded"></div>
        </div>
        <div className="flex flex-row gap-2 mt-2 ml-2">
          <div className="w-7 h-7 rounded-full bg-slate-200"></div>
          <div className="w-7 h-7 rounded-full bg-slate-200"></div>
          <div className="w-7 h-7 rounded-full bg-slate-200"></div>
        </div>
      </div>
    )
  }

  return (
    <div className="bg-white p-4 border-b">
      <div className="w-full flex flex-row items-center gap-2 pb-1">
        <div className="flex flex-row items-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="w-5 h-5">
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M5.25 8.25h15m-16.5 7.5h15m-1.8-13.5l-3.9 19.5m-2.1-19.5l-3.9 19.5"
            />
          </svg>
          <h1 className="text-2xl font-bold tracking-wide text-gray-900">{data.name}</h1>
        </div>
        <div>
          {!isEditing && (
            <button
              className="btn btn-ghost btn-xs btn-circle"
              onClick={() => {
                setEditing(true)
              }}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                stroke="currentColor"
                className="w-4 h-4">
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L6.832 19.82a4.5 4.5 0 01-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 011.13-1.897L16.863 4.487zm0 0L19.5 7.125"
                />
              </svg>
            </button>
          )}
        </div>
      </div>
      <People userIds={data?.members?.map((member: any) => member.personId)} showMax={20} />
      {isEditing && (
        <div className="mt-2">
          <div className="border border-gray-300 rounded-3xl px-2">
            <PeoplePicker
              defaultSelectedUserIds={teamMembersIds}
              selectionChanged={(e: any) => {
                setTeamMembersIds(e.target.selectedPeople.map((person: any) => person.id))
              }}
            />
          </div>
          <div className="flex flex-row-reverse gap-2 mt-2">
            <button
              className={classNames('btn', {
                loading: isSubmitting,
              })}
              onClick={() => {
                void updateMembers()
              }}
              disabled={isSubmitting}>
              Save
            </button>
            <button
              className="btn btn-ghost"
              onClick={() => {
                setEditing(false)
              }}>
              Cancel
            </button>
          </div>
        </div>
      )}
    </div>
  )
}

function TeamHistory({ id }: { id: string }) {
  const [from] = useState<Date>(startOfDay(subDays(new Date(), 4)))
  const [days, setDays] = useState<string[]>([])

  const params = useMemo(
    () => ({
      from: from.getTime(),
    }),
    [from]
  )

  useEffect(() => {
    const range = eachDayOfInterval({
      start: from,
      end: new Date(),
    })

    setDays(range.map((d) => format(d, 'dd/MM')))
  }, [from])

  const { data: checkIns, isLoading } = useSWR(
    [`/analytics/team/${id}/check-ins`, params],
    async ([url, params]) => await fetcher(url, params)
  )

  const [reducedCheckIns, setReducedCheckIns] = useState()

  useEffect(() => {
    if (checkIns?.data) {
      const transformedResults = checkIns.data.reduce(
        (r: any, checkIn: { personId: string; hours: number; postedAt: Date }) => {
          const { personId, hours, postedAt } = checkIn
          const date = format(new Date(postedAt), 'dd/MM')

          if (!r[personId]) {
            r[personId] = {
              [date]: hours,
            }
          } else {
            if (r[personId][date]) {
              r[personId][date] = (Number(r[personId][date]) || 0) + hours
            } else {
              r[personId][date] = hours
            }
          }

          return r
        },
        {}
      )

      setReducedCheckIns(transformedResults)
    }
  }, [checkIns])

  return (
    <table className="divide-y divide-gray-200 text-xs min-w-full">
      <thead>
        <tr>
          <th className="p-1"></th>
          {days.map((d, i) => (
            <th key={`col-${i}`} className="p-1 font-light">
              {d}
            </th>
          ))}
        </tr>
      </thead>
      <tbody className="divide-y divide-gray-200">
        {!isLoading &&
          reducedCheckIns &&
          Object.entries(reducedCheckIns).map(([personId, checkIns]: [string, any]) => (
            <tr key={personId}>
              <td className="p-1">
                <Person userId={personId} personCardInteraction={PersonCardInteraction.hover} />
              </td>
              <>
                {days.map((d, i) => (
                  <td key={`col-d-${i}`} className="text-center p-1 font-light">
                    {!checkIns[d] ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        className="w-4 h-4 mx-auto text-red-500">
                        <path
                          fillRule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.28 7.22a.75.75 0 00-1.06 1.06L8.94 10l-1.72 1.72a.75.75 0 101.06 1.06L10 11.06l1.72 1.72a.75.75 0 101.06-1.06L11.06 10l1.72-1.72a.75.75 0 00-1.06-1.06L10 8.94 8.28 7.22z"
                          clipRule="evenodd"
                        />
                      </svg>
                    ) : (
                      <span>{checkIns[d]}h</span>
                    )}
                  </td>
                ))}
              </>
            </tr>
          ))}
        {isLoading && (
          <>
            <tr className="animate-pulse">
              <td className="p-1 text-center">
                <div className="w-6 h-6 bg-slate-300 rounded-full"></div>
              </td>
              {[1, 2, 3, 4, 5, 6].map((_, i) => (
                <td key={i} className="p-1 text-center">
                  <div className="w-3 h-3 bg-slate-300 rounded-full"></div>
                </td>
              ))}
            </tr>
            <tr className="animate-pulse">
              <td className="p-1 text-center">
                <div className="w-6 h-6 bg-slate-300 rounded-full"></div>
              </td>
              {[1, 2, 3, 4, 5, 6].map((_, i) => (
                <td key={i} className="p-1 text-center">
                  <div className="w-3 h-3 bg-slate-300 rounded-full"></div>
                </td>
              ))}
            </tr>
          </>
        )}
      </tbody>
    </table>
  )
}

export default function Team() {
  const { id } = useParams() as { id: string }
  const [show30DayHistory, setShow30DayHistory] = useState(false)

  const {
    data: feed,
    isLoading: feedLoading,
    error,
  } = useSWR(`/timeline?teamId=${id}`, fetcher, {
    refreshInterval: 60 * 1000,
  })

  return (
    <div className="grid grid-cols-1 md:grid-cols-3 gap-2">
      <div className="col-span-2 border">
        <TeamProfile id={id} />
        <Feed
          error={error}
          loading={feedLoading}
          items={feed?.data?.map((post: any) => ({
            id: post.id,
            authorId: post.authorId,
            timestamp: post.createdAt,
            hours: post.hours,
            content: post.content,
            replies: post.replies,
          }))}
        />
      </div>
      <div>
        <div className="col-span-1 px-2">
          <div className="bg-gray-100 rounded py-2 shadow">
            <div className="p-2 flex flex-row items-center justify-between">
              <h3 className="text-lg font-semibold">Last 5 days</h3>
              <button
                onClick={() => {
                  setShow30DayHistory(true)
                }}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  className="w-5 h-5 text-gray-700 hover:text-purple-500">
                  <path d="M13.28 7.78l3.22-3.22v2.69a.75.75 0 001.5 0v-4.5a.75.75 0 00-.75-.75h-4.5a.75.75 0 000 1.5h2.69l-3.22 3.22a.75.75 0 001.06 1.06zM2 17.25v-4.5a.75.75 0 011.5 0v2.69l3.22-3.22a.75.75 0 011.06 1.06L4.56 16.5h2.69a.75.75 0 010 1.5h-4.5a.747.747 0 01-.75-.75zM12.22 13.28l3.22 3.22h-2.69a.75.75 0 000 1.5h4.5a.747.747 0 00.75-.75v-4.5a.75.75 0 00-1.5 0v2.69l-3.22-3.22a.75.75 0 10-1.06 1.06zM3.5 4.56l3.22 3.22a.75.75 0 001.06-1.06L4.56 3.5h2.69a.75.75 0 000-1.5h-4.5a.75.75 0 00-.75.75v4.5a.75.75 0 001.5 0V4.56z" />
                </svg>
              </button>
            </div>
            <TeamHistory id={id} />
            <Team30DaysCheckIns
              teamId={id}
              isOpen={show30DayHistory}
              onClose={() => {
                setShow30DayHistory(false)
              }}
            />
          </div>
        </div>
      </div>
    </div>
  )
}
