import { getApiAccessToken } from '../msal'

const fetcher = async (url: string, params?: Record<string, string | number | undefined>) => {
  const accessToken = await getApiAccessToken()
  let apiUrl = process.env.REACT_APP_API_URL + url
  const usp = new URLSearchParams()

  if (params) {
    for (const [key, value] of Object.entries(params)) {
      value && usp.append(key, value.toString())
    }

    apiUrl += '?' + usp.toString()
  }

  return await fetch(apiUrl, {
    headers: {
      Authorization: `Bearer ${accessToken!}`,
    },
  }).then(async (res) => await res.json())
}

export default fetcher
