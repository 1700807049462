import { Person as MGTPerson, PersonViewType } from '@microsoft/mgt-react'
import { startOfDay, subDays, endOfDay, format } from 'date-fns'
import { useMemo } from 'react'
import Feed from '../../components/Feed'
import fetcher from '../../lib/fetcher'
import { useAccount } from '@azure/msal-react'
import useSWR from 'swr'

export default function Profile() {
  const account = useAccount()

  const params = useMemo(
    () => ({
      from: startOfDay(subDays(new Date(), 30)).toISOString(),
      to: endOfDay(new Date()).toISOString(),
      personId: account?.localAccountId,
    }),
    [account]
  )

  const {
    data: feed,
    isLoading: feedLoading,
    error,
  } = useSWR(account ? `/timeline?personId=${account.localAccountId}` : null, fetcher)
  const { data: presenceHistory, isLoading: presenceHistoryLoading } = useSWR(
    params.personId ? ['/analytics/person/presence', params] : null,
    async ([url, params]) => await fetcher(url, params)
  )

  return (
    <div className="grid grid-cols-1 md:grid-cols-3">
      <div className="col-span-2 border">
        <div className="bg-white p-4 border-b">
          <MGTPerson personQuery="me" view={PersonViewType.threelines} />
        </div>
        <Feed
          loading={feedLoading}
          error={error}
          items={feed?.data?.map((post: any) => ({
            id: post.id,
            authorId: post.authorId,
            timestamp: post.createdAt,
            hours: post.hours,
            content: post.content,
            replies: post.replies,
          }))}
        />
      </div>
      <div className="col-span-1 mt-4 md:px-4 md:mt-0">
        <div className="bg-gray-100/20 p-4 rounded">
          {!presenceHistoryLoading && presenceHistory && (
            <>
              <p className="text-lg tracking-wide text-gray-800">
                You have booked{' '}
                <span className="font-bold text-gray-900">
                  {presenceHistory.data.reduce(
                    (sum: number, item: any) => sum + Number(item.hours) || 0,
                    0
                  )}
                </span>{' '}
                <span className="font-bold text-gray-900">hours</span> in last 30 days.
              </p>
              <ul className="text-sm text-gray-600 divide-y mt-5">
                {presenceHistory?.data?.map((item: any, i: number) => (
                  <li key={`history-item${i}`} className="py-2">
                    <div className="flex flex-row justify-between items-center">
                      <span>{format(new Date(item.date), 'dd/MM - EEE')}</span>
                      {item.hours !== '0' && <span>{item.hours} hrs</span>}
                      {(!item.hours || item.hours === '0') && <span>-</span>}
                    </div>
                  </li>
                ))}
              </ul>
            </>
          )}
        </div>
      </div>
    </div>
  )
}
