import classNames from 'classnames'
import { useRef, useState } from 'react'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.bubble.css'
import { PeoplePicker, Person } from '@microsoft/mgt-react'
import { useNavigate } from 'react-router-dom'
import { RadioGroup } from '@headlessui/react'
import Feed from '../../components/Feed'
import useSWR from 'swr'
import fetcher from '../../lib/fetcher'
import LoadingDots from '../../components/LoadingDots'
import FollowUp from '../../components/FollowUp/FollowUp'

export default function Home() {
  const navigate = useNavigate()

  const [value, setValue] = useState('')
  const [isSubmitting, setSubmitting] = useState(false)
  const [hours, setHours] = useState(4)
  const [showFollowUp, setShowFollowUp] = useState(false)

  const {
    data: feed,
    isLoading,
    error,
    mutate,
  } = useSWR(`/timeline`, fetcher, {
    refreshInterval: 60 * 1000,
  })

  const quillRef = useRef<ReactQuill>(null)

  const onShare = async () => {
    setSubmitting(true)
    if (quillRef?.current?.unprivilegedEditor) {
      const text = quillRef.current.unprivilegedEditor.getText()
      if (text.trim().length === 0) {
        setSubmitting(false)
        return
      }
    }

    setShowFollowUp(true)
    setSubmitting(false)
  }

  return (
    <div className="grid grid-cols-1 md:grid-cols-3">
      <div className="col-span-2">
        <div className="flex flex-col gap-4 border">
          <div className="w-full px-4 pt-2 bg-white border-b">
            <div className="flex flex-row">
              <Person personQuery="me" className="py-2" avatarSize="large" />
              <div className="flex-1">
                <ReactQuill
                  ref={quillRef}
                  theme="bubble"
                  placeholder="Share with everyone what you accomplished today.."
                  value={value}
                  onChange={(value) => {
                    setValue(value)
                  }}
                  formats={['bold', 'italic', 'underline', 'list', 'bullet']}
                />
              </div>
            </div>
            <div className="border-t flex flex-row justify-between items-center mt-4 p-2">
              <RadioGroup value={hours} onChange={setHours}>
                <div className="flex gap-1">
                  {[1, 2, 4, 8].map((option) => (
                    <RadioGroup.Option
                      key={option}
                      value={option}
                      className={({ active, checked }) =>
                        classNames(
                          active ? 'ring-2 ring-indigo-500 ring-offset-1' : '',
                          checked
                            ? 'border-transparent bg-primary text-white'
                            : 'border-gray-200 bg-gray-200 text-gray-900 hover:bg-gray-300',
                          'w-6 h-6 flex items-center justify-center rounded-full border p-1 text-xs text-gray-500 sm:flex-1 hover:cursor-pointer'
                        )
                      }>
                      <RadioGroup.Label as="span">{option}h</RadioGroup.Label>
                    </RadioGroup.Option>
                  ))}
                </div>
              </RadioGroup>
              <button
                className="btn"
                disabled={!value || value.length === 0}
                onClick={() => {
                  if (!isSubmitting) {
                    void onShare()
                  }
                }}>
                {isSubmitting ? <LoadingDots /> : 'Share'}
              </button>
            </div>
          </div>
          <Feed
            loading={isLoading}
            error={error}
            items={feed?.data?.map((post: any) => ({
              id: post.id,
              authorId: post.authorId,
              timestamp: post.createdAt,
              hours: post.hours,
              content: post.content,
              replies: post.replies,
            }))}
          />
        </div>
      </div>
      <div className="col-span-1">
        <div className="px-2 sticky inset-2">
          <div className="bg-gray-200 rounded-3xl px-2 flex flex-row items-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1}
              stroke="currentColor"
              className="w-5 h-5 text-gray-500">
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
              />
            </svg>

            <PeoplePicker
              placeholder="Find a colleague"
              selection-mode="single"
              selectionChanged={(e: any) => {
                const targetPersonID: string = e.target.selectedPeople[0].id
                navigate(`/${targetPersonID}`)
              }}
            />
          </div>
        </div>
      </div>
      {showFollowUp && (
        <FollowUp
          isOpen={showFollowUp}
          onReplied={() => {
            setShowFollowUp(false)
            setValue('')
            void mutate()
          }}
          firstInput={value}
          hours={hours}
          onDismiss={() => {
            console.log('TODO: feature not implemented')
          }}
        />
      )}
    </div>
  )
}
