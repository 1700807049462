import { Person as MGTPerson, PersonViewType } from '@microsoft/mgt-react'
import { useParams } from 'react-router-dom'
import Feed from '../../components/Feed'
import useSWR from 'swr'
import fetcher from '../../lib/fetcher'
import { endOfDay, format, startOfDay, subDays } from 'date-fns'
import { useMemo } from 'react'

export default function Person() {
  const { id } = useParams()

  const params = useMemo(
    () => ({
      from: startOfDay(subDays(new Date(), 10)).toISOString(),
      to: endOfDay(new Date()).toISOString(),
      personId: id!,
    }),
    [id]
  )

  const { data: feed, isLoading: feedLoading, error } = useSWR(`/timeline?personId=${id!}`, fetcher)
  const { data: presenceHistory, isLoading: presenceHistoryLoading } = useSWR(
    ['/analytics/person/presence', params],
    async ([url, params]) => await fetcher(url, params)
  )

  return (
    <div className="grid grid-cols-1 md:grid-cols-3">
      <div className="col-span-2 border">
        <div className="bg-white p-4 border-b">
          <MGTPerson userId={id} view={PersonViewType.threelines} />
        </div>
        <Feed
          loading={feedLoading}
          error={error}
          items={feed?.data?.map((post: any) => ({
            id: post.id,
            authorId: post.authorId,
            timestamp: post.createdAt,
            hours: post.hours,
            content: post.content,
            replies: post.replies,
          }))}
        />
      </div>
      <div className="col-span-1 mt-4 md:px-4 md:mt-0">
        <div className="bg-gray-100 p-4 rounded">
          <h3 className="text-lg font-bold tracking-wide">Last 10 days</h3>
          {!presenceHistoryLoading && presenceHistory && (
            <ul className="text-sm text-gray-600 divide-y">
              {presenceHistory?.data?.map((item: any, i: number) => (
                <li key={`history-item${i}`} className="py-2">
                  <div className="flex flex-row justify-between items-center">
                    <span>{format(new Date(item.date), 'MMM dd')}</span>
                    {item.hours !== '0' && <span>{item.hours} hrs</span>}
                    {(!item.hours || item.hours === '0') && <span>-</span>}
                  </div>
                </li>
              ))}
            </ul>
          )}
        </div>
      </div>
    </div>
  )
}
