import classNames from 'classnames'
import { NavLink, Outlet } from 'react-router-dom'
import fetcher from '../lib/fetcher'
import useSWR from 'swr'

interface Team {
  id: string
  name: string
}

export default function Root() {
  const { data: teams, isLoading: teamsLoading } = useSWR<Team[]>('/my-teams', fetcher)

  return (
    <div>
      <div className="container mx-auto py-2">
        <div className="md:flex md:w-72 md:flex-col md:fixed mb-6 md:mb-0 bg-white w-full px-4">
          <div className="flex flex-col flex-grow overflow-x-hidden overflow-y-auto divide-y">
            <div className="flex flex-col">
              <nav className="flex-1 space-y-1">
                <NavLink
                  to="/"
                  className={({ isActive }) =>
                    classNames(
                      isActive
                        ? 'bg-gray-200  text-gray-900'
                        : 'text-gray-600 hover:bg-gray-200 hover:text-gray-900',
                      'group flex items-center px-4 py-2 text-sm font-medium rounded-3xl'
                    )
                  }>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    stroke="currentColor"
                    className="w-5 h-5 mr-2">
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
                    />
                  </svg>
                  Home
                </NavLink>
                <NavLink
                  to="/me"
                  className={({ isActive }) =>
                    classNames(
                      isActive
                        ? 'bg-gray-200  text-gray-900'
                        : 'text-gray-600 hover:bg-gray-200 hover:text-gray-900',
                      'group flex items-center px-4 py-2 text-sm font-medium rounded-3xl'
                    )
                  }>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6">
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z"
                    />
                  </svg>
                  Profile
                </NavLink>
                {!teamsLoading &&
                  teams?.map((team) => (
                    <NavLink
                      key={team.id}
                      to={`/teams/${team.id}`}
                      className={({ isActive }) =>
                        classNames(
                          isActive
                            ? 'bg-gray-200 text-gray-900'
                            : 'text-gray-600 hover:bg-gray-200 hover:text-gray-900',
                          'group flex items-center px-4 py-2 text-sm font-medium rounded-3xl'
                        )
                      }>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        stroke="currentColor"
                        className="w-5 h-5  mr-2">
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M5.25 8.25h15m-16.5 7.5h15m-1.8-13.5l-3.9 19.5m-2.1-19.5l-3.9 19.5"
                        />
                      </svg>

                      {team.name}
                    </NavLink>
                  ))}
                {teamsLoading && (
                  <div className="flex flex-col gap-y-1">
                    <div className="h-9 w-full animate-pulse bg-slate-200 rounded-3xl"></div>
                    <div className="h-9 w-full animate-pulse bg-slate-200 rounded-3xl"></div>
                    <div className="h-9 w-full animate-pulse bg-slate-200 rounded-3xl"></div>
                  </div>
                )}
              </nav>
              <NavLink to="/teams/add" className="mt-4">
                <button className="btn w-full">Add Team</button>
              </NavLink>
            </div>
          </div>
        </div>
        <div className="px-4 md:px-0 md:pl-72 flex flex-col flex-1">
          <main className="relative">
            <Outlet />
          </main>
        </div>
      </div>
    </div>
  )
}
